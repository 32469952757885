import './components.css';
import { BonkText } from '../BonkText';
import { BonkButton } from '../BonkButton';
import BonkContainer from '../BonkContainer';
import { Link } from 'react-router-dom';
import { BonkInput } from '../BonkInput';
import { BonkBadge } from '../BonkBadge';
import { BonkPattern } from '../BonkPattern';
import BonkFooterBody from '../BonkFooterBody';
import BonkFooterCategory from '../BonkFooterCategory';
import { BonkFooterLink } from '../BonkFooterLink';
import BonkFieldset from '../BonkFieldset';
import { BonkBullet } from '../BonkBullet';
import BonkDate from '../BonkDate';

export function CDefault() {
    return (
        <div className='component-body'>
            <div className='component-content flex column'>
                <BonkText title='search for an component' titleColor='BLACK' />

                <div className='component-grid'>
                    <Link className='c-element' to='/components?component=bonkbadge'>
                        <div /> {/* overlay for the elements that you can not interact with the component */}
                        <BonkBadge number='420K+' title='badge' color='GRADIENT' size='MEDIUM' />
                    </Link>
                    <Link className='c-element' to='/components?component=bonkbullet'>
                        <div />
                        <BonkFieldset title='Fieldset' color='GRAY' id='set1'>
                            <BonkBullet color='GRAY' text='Bullet' name='set1'/>
                            <BonkBullet color='GRAY' text='Bullet' name='set1' defaultChecked={true} />
                            <BonkBullet color='GRAY' text='Bullet' name='set1'/>
                        </BonkFieldset>
                    </Link>
                    <Link className='c-element' to='/components?component=bonkbutton'>
                        <div />
                        <BonkButton text='button' color='RED' size='LARGE' hoverAnimation={false} fullWidth={true} />
                    </Link>
                    <Link className='c-element' to='/components?component=bonkcontainer'>
                        <div />
                        <BonkContainer color='PEACH' width='calc(var(--component-box-size) / 1.3)' title='container' logo={false} shadow={false} style={{display: 'flex', gap: '10px', flexDirection: 'column'}}><BonkInput fullWidth color='ORANGE' /><BonkButton fullWidth color='WHITE' text='submit' /></ BonkContainer>
                    </Link>
                    <Link className='c-element' to='/components?component=bonkdate'>
                        <div />
                        <BonkDate />
                    </Link>
                    <Link className='c-element' to='/components?component=bonkfooterbody'>
                        <div />
                        <BonkFooterBody color='RED' socials={false} className='preview-footer'>
                            <BonkFooterCategory title='footer'>
                                <BonkFooterLink title='Link' />
                                <BonkFooterLink title='Link' />
                            </BonkFooterCategory>
                        </BonkFooterBody>
                    </Link>
                    <Link className='c-element' to='/components?component=bonkinput'>
                        <div />
                        <BonkInput title='input field' description='Bottom descriptive text' color='MAROON' fullWidth={true} />
                    </Link>
                    <Link className='c-element' to='/components?component=bonkpattern'>
                        <div className='flex center items-center'>
                            <BonkText size='LARGE' title='pattern' />
                        </div>
                        <div className='pattern-background black'>
                            <BonkPattern
                                width='var(--component-box-size)'
                                height='var(--component-box-size)'
                                opacity={20}
                                size={500}
                            />
                        </div>
                    </Link>
                    <Link className='c-element' to='/components?component=bonktext'>
                        <div />
                        <BonkText size='LARGE' title='text' titleColor='RED' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit [...]' descriptionColor='BLACK' />
                    </Link>
                </div>
            </div>
        </div>
    );
}
