import './BonkBadge.css';
import { componentWarnLog } from '../utils/consoleMessage';

/**
 * BONKBADGE COMPONENT
 * Returns a Badge to display a number and a descriptive text
 * @param number Badge top number (case-sensitive)
 * @param title Badge title text (not case-sensitive)
 * @param color Badge color -- Available options: PEACH (default), GRADIENT or TRANSPARENT
 * @param size Badge size -- Available options: SMALL, MEDIUM (default), LARGE
 */
export function BonkBadge(
    props: Readonly<{
        number: string,
        title?: string,
        color?: string,
        size?: string,

        style?: any,
        className?: string,
        [key: string]: any
    }>
) {
    const {number, title, color, size, style, className, ...overflowProps} = props;

    let badgeColor = "bcl-PEACH"; /* default: bcl-PEACH */
    switch (color) {
        case "PEACH": break;
        case "GRADIENT": badgeColor = "bcl-GRADIENT"; break;
        case "TRANSPARENT": badgeColor = "bcl-TRANSPARENT"; break;

        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkBadge", `Color "${color}" not available`, "PEACH | GRADIENT | TRANSPARENT"); break;
    }

    let badgeSize = "bcl-MEDIUM"; /* default: bcl-MEDIUM */
    switch (size) {
        case "SMALL": badgeSize = "bcl-SMALL"; break;
        case "MEDIUM": break;
        case "LARGE": badgeSize = "bcl-LARGE"; break;

        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkBadge", `Size "${size}" not available`, "SMALL | MEDIUM | LARGE"); break;
    }

    return (
        <div className={`bcl-badge ${badgeColor} ${badgeSize} ${className || ""}`} {...overflowProps}>
            <h2 className="bcl">{number}</h2>
            {title !== undefined && <p className="bcl">{title}</p>}
        </div>
    );
}
