import { ReactNode } from 'react';
import './BonkFooterCategory.css';
import { BonkText } from './BonkText';

/**
 * BONKFOOTERCATEGORY COMPONENT
 * Category component for the BonkFooterBody
 * @param title Footer category title text (not case-sensitive)
 */
const BonkFooterCategory: React.FC<{
    title: string,

    className?: string,
    children?: ReactNode,
    [key: string]: any
}> = (props) => {
    const {title, className, children, style, ...overflowProps} = props;

    return (
        <div className={`blc-footer-category ${className || ""}`} {...overflowProps}>
            <BonkText size="SMALL" title={title} />
            {children}
        </div>
    );
}

export default BonkFooterCategory;