import './App.css';
import "./static/styles/layout.css";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Header } from './components/site/Header';
import Home from './pages/Home';
import Components from "./pages/Components";
import Colors from "./pages/Colors";
import Example from "./pages/Example"
import { Footer } from './components/site/Footer';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/components" element={<Components />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/example" element={<Example />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
