import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import "./components.css";
import { BonkPattern } from "../BonkPattern";
import BonkDate from "../BonkDate";

const code =
`<BonkDate
    fullWidth={true}
    color="BLACK"
/>`;

export function CBonkDate() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Retruns a date control component"
                updatedOn="09/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" />
                    <p className="bcl parameters">
                        <span>fullWidth</span> boolean<br />
                        <span className="desc">Sets button width to 100% (disabled by default)</span><br /><br />

                        <span>color</span> string<br />
                        <span className="desc">Date selector color -- Available options: PEACH (default), BLACK or WHITE</span><br /><br />

                        <span>title</span> string<br />
                        <span className="desc">Title text above the date (not case-sensitive)</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <BonkDate color="WHITE" title="Date" />
                        <div className="preview-background-dark">
                            <div className="flex gap column">
                                <BonkDate color="PEACH" title="Date" />
                                <BonkDate color="BLACK" title="Date" />
                            </div>
                            <BonkText description="Dark Background" />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="The best way to implement BonkInput components is within BonkContainer components.<br /><br />Optional parameters do not necessarily have to be defined. The specified default value is then applied." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
