import './Colors.css';
import { BonkText } from '../components/BonkText';

function Home() {
    async function handleCopyColor(color: string) {
        try {
            await navigator.clipboard.writeText(color);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="all">
            <div className="content-limiter mt-5 mb-5 flex gap column items-center">
                <BonkText titleColor="BLACK" className="mb-4" size="LARGE" title="colors & gradients" />
                <div className="colors-gradients-content w-50 flex column gap">
                    <BonkText titleColor="BLACK" className="mb-4" size="SMALL" title="colors" />

                    <div className="colors-gradients-container">
                        <div onClick={() => handleCopyColor("--color-yellow")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-yellow" />
                            <div style={{ backgroundColor: "var(--color-yellow)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#FFD302" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-sand")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-sand" />
                            <div style={{ backgroundColor: "var(--color-sand)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#fad99f" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-orange")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-orange" />
                            <div style={{ backgroundColor: "var(--color-orange)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#FC8E01" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-peach")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-peach" />
                            <div style={{ backgroundColor: "var(--color-peach)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#FF5C01" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-silver")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-silver" />
                            <div style={{ backgroundColor: "var(--color-silver)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#90a6cb" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-gray")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-gray" />
                            <div style={{ backgroundColor: "var(--color-gray)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#888888" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-dark-blue")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-dark-blue" />
                            <div style={{ backgroundColor: "var(--color-dark-blue)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#2B3649" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-black")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-black" />
                            <div style={{ backgroundColor: "var(--color-black)" }}>
                                <BonkText descriptionColor="MEDIUMGRAY" size="SMALL" description="#000205" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-red")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-red" />
                            <div style={{ backgroundColor: "var(--color-red)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#FF0000" />
                            </div>
                        </div>
                        <div onClick={() => handleCopyColor("--color-maroon")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--color-maroon" />
                            <div style={{ backgroundColor: "var(--color-maroon)" }}>
                                <BonkText descriptionColor="BLACK" size="SMALL" description="#800000" />
                            </div>
                        </div>
                    </div>

                    <BonkText titleColor="BLACK" className="mb-4" size="SMALL" title="gradients" />

                    <div className="colors-gradients-container">
                        <div onClick={() => handleCopyColor("--gradient-diag-oy")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--gradient-diag-oy" />
                            <div style={{ background: "var(--gradient-diag-oy)" }} />
                        </div>
                        <div onClick={() => handleCopyColor("--gradient-ver-oy")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--gradient-ver-oy" />
                            <div style={{ background: "var(--gradient-ver-oy)" }} />
                        </div>
                        <div onClick={() => handleCopyColor("--gradient-diag-py")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--gradient-diag-py" />
                            <div style={{ background: "var(--gradient-diag-py)" }} />
                        </div>
                        <div onClick={() => handleCopyColor("--gradient-ver-py")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--gradient-ver-py" />
                            <div style={{ background: "var(--gradient-ver-py)" }} />
                        </div>
                        <div onClick={() => handleCopyColor("--gradient-diag-bg")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--gradient-diag-bg" />
                            <div style={{ background: "var(--gradient-diag-bg)" }} />
                        </div>
                        <div onClick={() => handleCopyColor("--gradient-ver-gb")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--gradient-ver-gb" />
                            <div style={{ background: "var(--gradient-ver-gb)" }} />
                        </div>
                        <div onClick={() => handleCopyColor("--gradient-diag-mr")}>
                            <BonkText descriptionColor="BLACK" size="SMALL" description="--gradient-diag-mr" />
                            <div style={{ background: "var(--gradient-diag-mr)" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
