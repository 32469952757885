import './Home.css';
import { BonkText } from '../components/BonkText';
import { CodeEditor } from '../components/CodeEditor';
import { BonkButton } from '../components/BonkButton';
import { Link } from 'react-router-dom';
import Logo from "../static/assets/logo.png";

const installCommandNPM = "npm install bonk-component-library";
const installCommandYARN = "yarn add bonk-component-library";
const usageCode = 'import BCL from "bonk-component-library"';

function Home() {
  return (
    <div className="all">
      <div className="content-limiter mt-5 mb-5 flex gap column items-center">
        <img src={Logo} width="150px" />
        <div className="flex column items-center">
          <BonkText titleColor="BLACK" size="LARGE" title="bonk component library" />
          <BonkText descriptionColor="BLACK" className="mb-4" size="SMALL" description="v1.0 (Last update 02/09/2024)" />
        </div>
        <div className="flex column items-center">
          <BonkText descriptionColor="BLACK" className="mb-2" size="SMALL" description="NPM Installation" />
          <CodeEditor language="typescript" code={installCommandNPM} width="500px" />
        </div>
        <div className="flex column items-center">
          <BonkText descriptionColor="BLACK" className="mb-2" size="SMALL" description="YARN Installation" />
          <CodeEditor language="typescript" code={installCommandYARN} width="500px" />
        </div>
        <div className="flex column items-center">
          <BonkText descriptionColor="BLACK" className="mb-2" size="SMALL" description="Usage" />
          <CodeEditor language="javascript" code={usageCode} width="500px" />
        </div>
      </div>
      <Link to="https://www.google.com/">
        <BonkButton text={"open the style guide"} hoverAnimation={true} />
      </Link>
    </div>
  );
}

export default Home;
