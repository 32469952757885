import { BonkBadge } from '../components/BonkBadge';
import { BonkBullet } from '../components/BonkBullet';
import { BonkButton } from '../components/BonkButton';
import BonkContainer from '../components/BonkContainer';
import BonkDate from '../components/BonkDate';
import BonkFieldset from '../components/BonkFieldset';
import BonkFooterBody from '../components/BonkFooterBody';
import BonkFooterCategory from '../components/BonkFooterCategory';
import { BonkFooterLink } from '../components/BonkFooterLink';
import { BonkInput } from '../components/BonkInput';
import { BonkPattern } from '../components/BonkPattern';
import { BonkText } from '../components/BonkText';
import { CodeEditor } from '../components/CodeEditor';
import './Example.css';

const code =
`<div style={{background: 'var(--gradient-diag-oy)', display: 'flex', flexDirection: 'column', gap: '3rem'}}>
    <div style={{padding: '3rem', display: 'flex', flexDirection: 'column', gap: '3rem'}}>
        <BonkText size='LARGE' title='BONK COMPONENT KIT' />

        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', gap: '1rem', flexWrap: 'wrap'}}>
            <BonkText style={{flex: '3'}} title='HEADER' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. In turpis tellus, sodales sit amet sapien at, euismod euismod nisi. Vestibulum ullamcorper lorem ligula, nec efficitur nisl fringilla sed. Proin vestibulum dolor quis vehicula sagittis.' />
            <BonkText style={{flex: '3'}} title='HEADER' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. In turpis tellus, sodales sit amet sapien at, euismod euismod nisi. Vestibulum ullamcorper lorem ligula, nec efficitur nisl fringilla sed. Proin vestibulum dolor quis vehicula sagittis.' />
            <BonkText style={{flex: '3'}} title='HEADER' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. In turpis tellus, sodales sit amet sapien at, euismod euismod nisi. Vestibulum ullamcorper lorem ligula, nec efficitur nisl fringilla sed. Proin vestibulum dolor quis vehicula sagittis.' />
        </div>
    </div>

    <div style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'var(--color-orange)', height: '300px'}}>
        <div style={{display: 'flex', gap: '2rem', flexWrap: 'wrap', justifyContent: 'center'}}>
            <BonkBadge number='420K+' title='BADGE' color='TRANSPARENT' />
            <BonkBadge number='69K+' title='BADGE' color='TRANSPARENT' />
        </div>
        <BonkPattern position='ABSOLUTE' opacity={15} size={300} width='100%' height='300px' />
    </div>

    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <BonkContainer color='WHITE' title='LONGER FORM' shadow logo style={{display: 'flex', flexDirection: 'column', gap: '20px'}} >
            <BonkInput title='PROJECT NAME' color='MAROON' fullWidth />
            <div style={{display: 'flex', gap: '20px'}}>
                <BonkInput title='DISCORD' color='MAROON' fullWidth />
                <BonkInput title='TWITTER' color='MAROON' fullWidth />
            </div>
            <BonkInput title='TEAM HANDLE' color='MAROON' fullWidth errorMessage='Pls complete field' />

            <BonkFieldset title='PRODUCT CATEGORY' id='set1' color='GRAY' >
                <BonkBullet text='DeFi' name='set1' color='GRAY' />
                <BonkBullet text='NFT' name='set1' color='GRAY' />
                <BonkBullet text='Gaming' name='set1' color='GRAY' defaultChecked />
                <BonkBullet text='Payments' name='set1' color='GRAY' />
                <BonkBullet text='Infrastructure' name='set1' color='GRAY' />
                <BonkBullet text='Other' name='set1' color='GRAY' />
            </BonkFieldset>

            <BonkInput title='COMMENTS' color='MAROON' fullWidth />

            <BonkButton text='submit' fullWidth />
        </BonkContainer>
    </div>

    <BonkFooterBody color='RED' socials>
        <BonkFooterCategory title='BONK'>
            <BonkFooterLink title='Foundation' link="/example" />
            <BonkFooterLink title='Solana Mobile' link="/example" />
            <BonkFooterLink title='Solana Pay' link="/example" />
            <BonkFooterLink title='Grants' link="/example" />
        </BonkFooterCategory>
        <BonkFooterCategory title='DEVELOPERS' link="/example">
            <BonkFooterLink title='Documentation' link="/example" />
            <BonkFooterLink title='Mobile SDK' link="/example" />
            <BonkFooterLink title='Pay SDK' link="/example" />
            <BonkFooterLink title='Cookbook' link="/example" />
            <BonkFooterLink title='DAOs' link="/example" />
        </BonkFooterCategory>
    </BonkFooterBody>
</div>`;

function Example() {
    return (
        <div className='example-container'>
            <div className='flex column flex-one'>
                <div style={{background: 'var(--gradient-diag-oy)', display: 'flex', flexDirection: 'column', gap: '3rem'}}>
                    <div style={{padding: '3rem', display: 'flex', flexDirection: 'column', gap: '3rem'}}>
                        <BonkText size='LARGE' title='BONK COMPONENT KIT' />

                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', gap: '1rem', flexWrap: 'wrap'}}>
                            <BonkText style={{flex: '3'}} title='HEADER' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. In turpis tellus, sodales sit amet sapien at, euismod euismod nisi. Vestibulum ullamcorper lorem ligula, nec efficitur nisl fringilla sed. Proin vestibulum dolor quis vehicula sagittis.' />
                            <BonkText style={{flex: '3'}} title='HEADER' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. In turpis tellus, sodales sit amet sapien at, euismod euismod nisi. Vestibulum ullamcorper lorem ligula, nec efficitur nisl fringilla sed. Proin vestibulum dolor quis vehicula sagittis.' />
                            <BonkText style={{flex: '3'}} title='HEADER' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. In turpis tellus, sodales sit amet sapien at, euismod euismod nisi. Vestibulum ullamcorper lorem ligula, nec efficitur nisl fringilla sed. Proin vestibulum dolor quis vehicula sagittis.' />
                        </div>
                    </div>

                    <div style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'var(--color-orange)', height: '300px'}}>
                        <div style={{display: 'flex', gap: '2rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                            <BonkBadge number='420K+' title='BADGE' color='TRANSPARENT' />
                            <BonkBadge number='69K+' title='BADGE' color='TRANSPARENT' />
                        </div>
                        <BonkPattern position='ABSOLUTE' opacity={15} size={300} width='100%' height='300px' />
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <BonkContainer color='WHITE' title='LONGER FORM' shadow logo style={{display: 'flex', flexDirection: 'column', gap: '20px'}} >
                            <BonkInput title='PROJECT NAME' color='MAROON' fullWidth />
                            <div style={{display: 'flex', gap: '20px'}}>
                                <BonkInput title='DISCORD' color='MAROON' fullWidth />
                                <BonkInput title='TWITTER' color='MAROON' fullWidth />
                            </div>
                            <BonkInput title='TEAM HANDLE' color='MAROON' fullWidth errorMessage='Pls complete field' />

                            <BonkFieldset title='PRODUCT CATEGORY' id='set1' color='GRAY' >
                                <BonkBullet text='DeFi' name='set1' color='GRAY' />
                                <BonkBullet text='NFT' name='set1' color='GRAY' />
                                <BonkBullet text='Gaming' name='set1' color='GRAY' defaultChecked />
                                <BonkBullet text='Payments' name='set1' color='GRAY' />
                                <BonkBullet text='Infrastructure' name='set1' color='GRAY' />
                                <BonkBullet text='Other' name='set1' color='GRAY' />
                            </BonkFieldset>

                            <BonkInput title='COMMENTS' color='MAROON' fullWidth />

                            <BonkButton text='submit' fullWidth />
                        </BonkContainer>
                    </div>

                    <BonkFooterBody color='RED' socials>
                        <BonkFooterCategory title='BONK'>
                            <BonkFooterLink title='Foundation' link="/example" />
                            <BonkFooterLink title='Solana Mobile' link="/example" />
                            <BonkFooterLink title='Solana Pay' link="/example" />
                            <BonkFooterLink title='Grants' link="/example" />
                        </BonkFooterCategory>
                        <BonkFooterCategory title='DEVELOPERS' link="/example">
                            <BonkFooterLink title='Documentation' link="/example" />
                            <BonkFooterLink title='Mobile SDK' link="/example" />
                            <BonkFooterLink title='Pay SDK' link="/example" />
                            <BonkFooterLink title='Cookbook' link="/example" />
                            <BonkFooterLink title='DAOs' link="/example" />
                        </BonkFooterCategory>
                    </BonkFooterBody>
                </div>
            </div>

            {/* code block */}
            <div className='flex-one'>
                <CodeEditor language='html' width='100%' code={code} />
            </div>
        </div>
    );
}

export default Example;
