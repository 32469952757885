import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import Logo from "../../static/assets/logo.png"
import { BonkText } from '../BonkText';

export function Header() {
    const location = useLocation();
    const {pathname} = location;

    return (
        <div className="header flex between items-center">
            <div className="flex ml-2">
                <div className={`category-tab ${(pathname === "/home" || pathname === "/") && "selected"}`}>
                    <Link to="/">
                        <BonkText descriptionColor="MEDIUMGRAY" size="SMALL" description="Home" />
                    </Link>
                </div>
                <div className={`category-tab ${pathname === "/components" && "selected"}`}>
                    <Link to="/components">
                        <BonkText descriptionColor="MEDIUMGRAY" size="SMALL" description="Components" />
                    </Link>
                </div>
                <div className={`category-tab ${pathname === "/colors" && "selected"}`}>
                    <Link to="/colors">
                        <BonkText descriptionColor="MEDIUMGRAY" size="SMALL" description="Colors & Gradients" />
                    </Link>
                </div>
                <div className={`category-tab ${pathname === "/example" && "selected"}`}>
                    <Link to="/example">
                        <BonkText descriptionColor="MEDIUMGRAY" size="SMALL" description="Example" />
                    </Link>
                </div>
            </div>
            {/*
            <Link to="/" className="mr-1">
                <img src={Logo} width="25px" />
            </Link>
            */}
        </div>
    );
}
