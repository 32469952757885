import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import { BonkBadge } from "../BonkBadge";
import "./components.css";

const code =
`<BonkBadge
    number="420K+"
    title="holders"
    color="GRADIENT"
    size="LARGE"
/>`;

export function CBonkBadge() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Returns a Badge to display a number and a descriptive text"
                updatedOn="08/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" />
                    <p className="bcl parameters">
                        <span>number</span> string <span className="third-field">required</span><br />
                        <span className="desc">Badge top number (case-sensitive)</span><br /><br />

                        <span>title</span> string<br />
                        <span className="desc">Badge title text (not case-sensitive)</span><br /><br />

                        <span>color</span> string<br />
                        <span className="desc">Badge color -- Available options: PEACH (default), GRADIENT or TRANSPARENT</span><br /><br />

                        <span>size</span> string<br />
                        <span className="desc">Badge size -- Available options: SMALL, MEDIUM (default), LARGE</span>
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <BonkBadge
                            number="420K+"
                            title="holders"
                            color="PEACH"
                            size="SMALL"
                        />
                        <BonkBadge
                            number="420K+"
                            title="holders"
                            color="PEACH"
                            size="MEDIUM"
                        />
                        <BonkBadge
                            number="420K+"
                            title="holders"
                            color="PEACH"
                            size="LARGE"
                        />
                        <BonkBadge
                            number="420K+"
                            title="holders"
                            color="GRADIENT"
                            size="SMALL"
                        />
                        <BonkBadge
                            number="420K+"
                            title="holders"
                            color="GRADIENT"
                            size="MEDIUM"
                        />
                        <BonkBadge
                            number="420K+"
                            title="holders"
                            color="GRADIENT"
                            size="LARGE"
                        />
                        <div className="preview-background-dark">
                            <div className="flex gap column">
                                <BonkBadge
                                    number="420K+"
                                    title="holders"
                                    color="TRANSPARENT"
                                    size="SMALL"
                                />
                                <BonkBadge
                                    number="420K+"
                                    title="holders"
                                    color="TRANSPARENT"
                                    size="MEDIUM"
                                />
                                <BonkBadge
                                    number="420K+"
                                    title="holders"
                                    color="TRANSPARENT"
                                    size="LARGE"
                                />
                            </div>
                            <BonkText description="Dark Background" />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="The total width/height is predetermined and should not be changed.<br /><br />Optional parameters do not necessarily have to be defined. The specified default value is then applied." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
