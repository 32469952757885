import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import "./components.css";
import { BonkPattern } from "../BonkPattern";

const code =
`<BonkPattern
    width="100%"
    height="300px"
    position="ABSOLUTE"
    size={490}
    opacity={20}
    mobileTrigger={800}
    mobileMultiplicator={2.1}
/>`;

export function CBonkPattern() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Returns a background logo pattern"
                updatedOn="09/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" />
                    <p className="bcl parameters">
                        <span>width</span> string<br />
                        <span className="desc">Pattern width (can be in "px", "%" or similar)</span><br /><br />

                        <span>height</span> string<br />
                        <span className="desc">Pattern height (can be in "px", "%" or similar)</span><br /><br />

                        <span>position</span> string<br />
                        <span className="desc">Pattern position -- Available options: RELATIVE (default) or ABSOLUTE</span><br /><br />

                        <span>size</span> string<br />
                        <span className="desc">Pattern logo size -- Range: 150 - 500 (default: 200)</span><br /><br />

                        <span>opacity</span> string<br />
                        <span className="desc">Pattern logo opacity -- Range: 5 - 20 (default: 10)</span><br /><br />

                        <span>mobileTrigger</span> string<br />
                        <span className="desc">When mobile view will be enabled (default: 768)</span><br /><br />

                        <span>mobileMultiplicator</span> string<br />
                        <span className="desc">Mobile view pattern size multiplicator (default: 2 (recommended))</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <div className="pattern-background gradient">
                            <BonkPattern
                                width="500px"
                                height="300px"
                                opacity={20}
                                size={200}
                            />
                        </div>
                        <div className="pattern-background black">
                            <BonkPattern
                                width="500px"
                                height="300px"
                                opacity={20}
                                size={500}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="You can position elements on the BonkPattern by making the BonkPattern an absolute object and increasing the z-index of other elements. Do not forget to set the position to relative for the parent element.<br /><br />The background color must be set manually in the body or in the parent div element.<br /><br />Optional parameters do not necessarily have to be defined. The specified default value is then applied." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
