import "./components.css";
import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import { BonkInput } from "../BonkInput";
import { BonkButton } from "../BonkButton";
import BonkContainer from "../BonkContainer";

const code =
    `<BonkContainer
    color="PEACH"
    width="350px"
    title="form"
    logo={true}
    shadow={true}
>
    <div style={{display: "flex", flexDirection: "column",  gap: "20px"}}>
        <BonkInput color="ORANGE" />
        <BonkText description="Descriptive text." />
        <BonkButton text="submit" color="WHITE" fullWidth />
    </div>
</BonkContainer>`;

export function CBonkContainer() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Provides a container designed for creating forms or similar structures"
                updatedOn="08/02/2024"
            />

            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" />
                    <p className="bcl parameters">
                        <span>color</span> string<br />
                        <span className="desc">Container color -- Available options: BLACK (default), PEACH or WHITE</span><br /><br />

                        <span>width</span> string<br />
                        <span className="desc">Container width (can be in "px", "%" or similar (default: 400px))</span><br /><br />

                        <span>title</span> string<br />
                        <span className="desc">Container title text (not case-sensitive)</span><br /><br />

                        <span>logo</span> boolean<br />
                        <span className="desc">Bonk logo to the left of the title text (disabled by default)</span><br /><br />

                        <span>shadow</span> boolean<br />
                        <span className="desc">White Container shadow (disabled by default)</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />

                    <div className="flex gap column">
                        <BonkContainer
                            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                            color="BLACK"
                            width="400px"
                            title="form"
                            logo={true}
                            shadow={false}
                        ><BonkInput fullWidth /><BonkButton fullWidth text="submit" /></ BonkContainer>
                        <BonkContainer
                            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                            color="PEACH"
                            width="400px"
                            title="form"
                            logo={true}
                            shadow={false}
                        ><BonkInput fullWidth color="ORANGE" /><BonkButton fullWidth color='WHITE' text="submit" /></ BonkContainer>
                        <div className="preview-background-dark">
                            <div className="flex gap column">
                                <BonkContainer
                                    style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                                    color="WHITE"
                                    width="400px"
                                    title="form"
                                    logo={true}
                                    shadow={false}
                                ><BonkInput fullWidth color="MAROON" /><BonkButton fullWidth text="submit" /></ BonkContainer>
                            </div>
                            <BonkText description="Dark Background" />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="When incorporating the BonkInput-, BonkButton- etc. components within the container, it is essential to adhere to the prescribed color combinations outlined in the BONK Style Guide.<br /><br />Optional parameters do not necessarily have to be defined. The specified default value is then applied.<br /><br />Influenced components by the autocorrect prop/Recommended components for implementation: BonkBullet (BonkFieldset), BonkButton, BonkInput, BonkText, BonkDate" descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
