import React from 'react';
import './BonkDate.css';
import { componentWarnLog } from '../utils/consoleMessage';

/**
 * BONKDATE COMPONENT
 * Retruns a date control component
 * @param fullWidth Sets button width to 100% (disabled by default)
 * @param color Date selector color -- Available options: PEACH (default), BLACK or WHITE
 * @param title Title text above the date (not case-sensitive)
 */
const BonkDate: React.FC<{
    fullWidth?: boolean,
    color?: string,
    title?: string,

    style?: any,
    className?: string,
    [key: string]: any
}> = (props) => {
    const {fullWidth, color, title, style, className, ...overflowProps} = props;

    let dateColor = "bcl-PEACH";
    switch (color) {
        case "PEACH": break;
        case "BLACK": dateColor = "bcl-BLACK"; break;
        case "WHITE": dateColor = "bcl-WHITE"; break;

        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkDate", `Color "${color}" not available`, "PEACH | BLACK | WHITE"); break;
    }

    return (
        <div className="bcl-date" style={style}>
            <h4 className={`bcl ${dateColor}`}>{title}</h4>
            <input className={`${dateColor} ${className || ""} ${fullWidth ? "bcl-FULLWIDTH" : ""}`} type="date" {...overflowProps} />
        </div>
    );
};

export default BonkDate;
