import './ComponentHead.css';
import { Link, useLocation } from 'react-router-dom';
import { BonkText } from '../BonkText';
import Return from "../../static/assets/return.png"

/**
 * COMPONENT TUTORIAL HEAD
 * @param description Short component description
 * @param updatedOn Format: MM/DD/YYYY
 */
export function ComponentHead(
    props: Readonly<{
        description?: string
        updatedOn?: string
    }>
) {
    const {description, updatedOn} = props;

    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const component = params.get("component");

    return (
        <div className="component-head">
            <div>
                <BonkText
                    title={`${(component !== null && component !== "") ? component : "component not found"}`}
                    titleColor="BLACK"
                />
                <BonkText
                    className="mt-2"
                    description={`${(description !== undefined && component !== "") ? description : ""}`}
                    descriptionColor="BLACK"
                    size="SMALL"
                />
                {(updatedOn !== undefined && component !== "") && (
                    <BonkText
                        className="mt-4"
                        description={`Updated on ${updatedOn}`}
                        descriptionColor="MEDIUMGRAY"
                        size="SMALL"
                    />
                )}
            </div>
            <Link to="/components">
                <img src={Return} width="30px" height="30px" title="Return" />
            </Link>
        </div>
    );
}
