import "./components.css";
import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";

const code =
`<BonkText
    size="LARGE"
    title="title"
    titleColor="RED"
    description="This is paragraph.<br />This is paragraph."
    descriptionColor="GRAY"
/>`;

export function CBonkText() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Returns a Title and/or a paragraph"
                updatedOn="07/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" />
                    <p className="bcl parameters">
                        <span>size</span> string<br />
                        <span className="desc">Text size (adapts for title and paragraph) -- Available options: SMALL, MEDIUM (default), LARGE</span><br /><br />

                        <span>title</span> string<br />
                        <span className="desc">Title text (not case-sensitive)</span><br /><br />

                        <span>titleColor</span> string<br />
                        <span className="desc">Title color -- Available options: WHITE (default) or RED</span><br /><br />

                        <span>description</span> string<br />
                        <span className="desc">Paragraph text (case-sensitive)</span><br /><br />

                        <span>descriptionColor</span> string<br />
                        <span className="desc">Paragraph color -- Available options: WHITE (default), SILVER or GRAY</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <BonkText
                            size="LARGE"
                            title="large title"
                            titleColor="RED"
                            description="This is a large paragraph. This is a large paragraph. This is a large paragraph. This is a large paragraph."
                            descriptionColor="GRAY"
                        />
                        <BonkText
                            size="MEDIUM"
                            title="medium title"
                            titleColor="RED"
                            description="This is a medium paragraph. This is a medium paragraph. This is a medium paragraph. This is a medium paragraph."
                            descriptionColor="GRAY"
                        />
                        <BonkText
                            size="SMALL"
                            title="small title"
                            titleColor="RED"
                            description="This is a small paragraph. This is a small paragraph. This is a small paragraph. This is a small paragraph."
                            descriptionColor="GRAY"
                        />
                        <div className="preview-background-dark">
                            <div className="flex gap column">
                                <BonkText
                                    size="LARGE"
                                    title="large title"
                                    description="This is a large paragraph. This is a large paragraph. This is a large paragraph. This is a large paragraph."
                                />
                                <BonkText
                                    size="MEDIUM"
                                    title="medium title"
                                    description="This is a medium paragraph. This is a medium paragraph. This is a medium paragraph. This is a medium paragraph."
                                />
                                <BonkText
                                    size="SMALL"
                                    title="small title"
                                    description="This is a small paragraph. This is a small paragraph. This is a small paragraph. This is a small paragraph."
                                />
                            </div>
                            <BonkText description="Dark Background" />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="When incorporating the BonkText component, it is essential to adhere to the prescribed color combinations outlined in the BONK Style Guide.<br /><br />It is important to ensure that BonkText components, are not merely placed with an undefined width; instead, strive to maintain a compact presentation at all times.<br /><br />Optional parameters do not necessarily have to be defined. If omitted, the parameter will either be completely excluded or the default value will be applied." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
