import React, { ReactNode } from 'react';
import './BonkContainer.css';
import Logo from "../static/assets/logo.png";
import { componentWarnLog } from '../utils/consoleMessage';

/**
 * BONKCONTAINER COMPONENT
 * Provides a container designed for creating forms or similar structures
 * @param color Container color -- Available options: BLACK (default), PEACH or WHITE
 * @param width Container width (can be in "px", "%" or similar (default: 400px))
 * @param title Container title text (not case-sensitive)
 * @param logo Bonk logo to the left of the title text (disabled by default)
 * @param shadow White Container shadow (disabled by default)
 */
/* later: i need to add a style prop so it wont get overwritten */
const BonkContainer: React.FC<{
    color?: string,
    width?: string,
    title?: string,
    logo?: boolean,
    shadow?: boolean,

    className?: string,
    children?: ReactNode,
    [key: string]: any
}> = (props) => {
    const {color, width, title, logo, shadow, children, className, style, ...overflowProps} = props;

    let containerColor = "bcl-BLACK"; /* default: bcl-BLACK */
    switch (color) {
        case "BLACK": break;
        case "PEACH": containerColor = "bcl-PEACH"; break;
        case "WHITE": containerColor = "bcl-WHITE"; break;
        
        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkContainer", `Color "${color}" not available`, "BLACK | PEACH | WHITE"); break;
    }

    let containerWidth = "400px"; /* default */
    if (width !== undefined) {
        containerWidth = width;
    }

    return (
        <div className={`bcl-form-container ${containerColor} ${shadow && "bcl-SHADOW"} ${className || ""}`} style={{ width: `${containerWidth}` }} {...overflowProps}>
            {title && (
                <div className="bcl-header">
                    {logo && <img alt="logo-bonk" src={Logo} width="60px" />}
                    <h2 className={`bcl ${containerColor === "bcl-WHITE" && "bcl-RED"}`}>{title}</h2>
                </div>
            )}
            <div style={style}>
                {children}
            </div>
        </div>
    );
};

export default BonkContainer;
