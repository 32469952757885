import './Components.css';
import { useLocation } from 'react-router-dom';
import { ComponentsNav } from '../components/site/ComponentsNav';
import { CBonkBadge } from '../components/components/CBonkBadge';
import { CBonkButton } from '../components/components/CBonkButton';
import { CBonkContainer } from '../components/components/CBonkContainer';
import { CDefault } from "../components/components/CDefault";
import { CBonkText } from '../components/components/CBonkText';
import { CBonkInput } from '../components/components/CBonkInput';
import { CBonkPattern } from '../components/components/CBonkPattern';
import { CBonkFooterBody } from '../components/components/CBonkFooterBody';
import { CBonkBullet } from '../components/components/CBonkBullet';
import { CBonkDate } from '../components/components/CBonkDate';

function Components() {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const component = params.get("component");
    
    return (
        <div className="components flex">
            <div className="c-tab">
                <ComponentsNav />
            </div>
            <div className="w-100">
                {/* works kind of like a router for the components page */}
                {(component === "" || component === null) && (<CDefault />)}
                
                {component === "bonkbadge" && (<CBonkBadge />)}
                {component === "bonkbutton" && (<CBonkButton />)}
                {component === "bonkcontainer" && (<CBonkContainer />)}
                {component === "bonktext" && (<CBonkText />)}
                {component === "bonkinput" && (<CBonkInput />)}
                {component === "bonkpattern" && (<CBonkPattern />)}
                {component === "bonkfooterbody" && (<CBonkFooterBody />)}
                {component === "bonkbullet" && (<CBonkBullet />)}
                {component === "bonkdate" && (<CBonkDate />)}
            </div>
        </div>
    );
}

export default Components;