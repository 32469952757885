import { componentWarnLog } from '../utils/consoleMessage';
import './BonkBullet.css';
import { BonkText } from './BonkText';

/**
 * BonkBullet COMPONENT
 * Returns a Bullet point (part of the BonkFieldset)
 * @param text Bullet text (case-sensitive)
 * @param name Bullet name/id
 * @param color Bullet color -- Available options: WHITE (default), GRAY or SILVER
 * @param defaultChecked Set bullet enabled by default (disabled by default)
 */
export function BonkBullet(
    props: Readonly<{
        text: string,
        color?: string,
        defaultChecked?: boolean,
        name?: string,

        style?: any,
        className?: string,
        [key: string]: any
    }>
) {
    const {text, name, color, defaultChecked, style, className, ...overflowProps} = props;

    let bulletColor = "WHITE"; /* default */
    switch (color) {
        case "WHITE": break;
        case "GRAY": bulletColor = "GRAY"; break;
        case "SILVER": bulletColor = "SILVER"; break;

        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkFieldset", `Color "${color}" not available`, "WHITE | GRAY | SILVER"); break;
    }

    return (
        <div className={`bcl-bullet ${className}`} style={style}>
            <input
                type="radio"
                name={name}
                defaultChecked={defaultChecked}

                {...overflowProps}
            />

            <BonkText descriptionColor={bulletColor} description={text} size="SMALL" />
        </div>
    );
}
