import './BonkInput.css';

/**
 * BONKINPUT COMPONENT
 * Returns an input field for forms or similar purposes
 * @param title Title text above the input field (not case-sensitive)
 * @param description Descriptive text below the input field (case-sensitive)
 * @param color Input field color -- Available options: BLACK (default), ORANGE or MAROON
 * @param errorMessage Error message on wrong input (case-sensitive) -- If the string is empty (""), the errormessage will be disabled
 * @param fullWidth Sets input field width to 100% (disabled by default)
 */
export function BonkInput(
    props: Readonly<{
        title?: string,
        description?: string,
        color?: string,
        errorMessage?: string,
        fullWidth?: boolean,

        style?: any,
        className?: string,
        [key: string]: any
    }>
) {
    const { title, description, color, errorMessage, className, fullWidth, style, ...overflowProps } = props;

    let inputColor = "bcl-BLACK"; /* default "bcl-BLACK" */
    switch (color) {
        case "BLACK": break;
        case "ORANGE": inputColor = "bcl-ORANGE"; break;
        case "MAROON": inputColor = "bcl-MAROON"; break;

        case undefined: break;
        case "": break;
        default: break;
    }

    return (
        <div className={`bcl-input ${fullWidth ? "bcl-FULLWIDTH" : ""} ${className || ""}`} style={style}>

            <div>
                <h4 className={`bcl ${inputColor}`}>{title}</h4>
                {(errorMessage !== undefined && errorMessage !== "") && (
                    <p className={`bcl-input-error ${inputColor}`}>{errorMessage}</p>
                )}
            </div>
            <input className={`${inputColor} ${(errorMessage !== undefined && errorMessage !== "") && "bcl-ERROR"}`} type="text" {...overflowProps} />
            {description && (
                <p className={`bcl ${inputColor} bcl-input-description`}>{description}</p>
            )}
        </div>
    );
}
