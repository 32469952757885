import React, { ReactNode } from 'react';
import './BonkFooterBody.css';
import Logo from "../static/assets/logo.png";
import YouTube from "../static/assets/youtube.png";
import X from "../static/assets/x.png";
import Discord from "../static/assets/discord.png";
import Reddit from "../static/assets/reddit.png";
import GitHub from "../static/assets/github.png";
import Telegram from "../static/assets/telegram.png";
import { componentWarnLog } from '../utils/consoleMessage';
import { BonkText } from './BonkText';

/**
 * BONKFOOTERBODY COMPONENT
 * Returns a footer.
 * @param color Footer color -- Available options: BLACK (default) or RED
 * @param socials Shows social component (enabled on default)
 */
const BonkFooterBody: React.FC<{
    color?: string,
    socials?: boolean,

    className?: string,
    children?: ReactNode,
    [key: string]: any
}> = (props) => {
    const {color, socials, className, children, style, ...overflowProps} = props;

    let footerType = "bcl-BLACK"; /* default: bcl-BLACK */
    switch (color) {
        case "BLACK": break;
        case "RED": footerType = "bcl-RED"; break;
        
        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkFooterBody", `Color "${color}" not available`, "BLACK | RED"); break;
    }

    let footerSocials = true;
    if (socials !== undefined) {
        footerSocials = socials;
    }

    return (
        <div className={`bcl-footer-body ${footerType} ${className || ""}`} {...overflowProps}>
            {footerSocials === true && (
                <div className="bcl-footer-socials">
                    <img alt="logo-bonk" src={Logo} width="250px" />
                    <div>
                        <div>
                            <a href="https://www.youtube.com/@bonk_inu">
                                <img alt="logo-youtube" src={YouTube} width="25px" />
                            </a>
                            <a href="https://twitter.com/bonk_inu">
                                <img alt="logo-x" src={X} width="25px" />
                            </a>
                            <a href="https://discord.com/invite/qaQa6M6mN2">
                                <img alt="logo-discord" src={Discord} width="25px" />
                            </a>
                            <a href="https://www.google.com">
                                <img alt="logo-reddit" src={Reddit} width="25px" />
                            </a>
                            <a href="https://www.google.com">
                                <img alt="logo-github" src={GitHub} width="25px" />
                            </a>
                            <a href="https://t.me/Official_Bonk_Inu">
                                <img alt="logo-telegram" src={Telegram} width="25px" />
                            </a>
                        </div>
                        <BonkText className="socials-text" size="SMALL" description="© 2024 Bonk Inu. All rights reserved." />
                    </div>
                </div>
            )}
            <div className="footer-children-container">
                {children}
            </div>
        </div>
    );
};

export default BonkFooterBody;
