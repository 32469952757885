import React, { ReactNode } from 'react';
import { componentWarnLog } from '../utils/consoleMessage';
import './BonkFieldset.css';

/**
 * BONKFIELDSET COMPONENT
 * Provides a fieldset for the BonkBullet component
 * @param title Title text (not case-sensitive)
 * @param color Fieldset color -- Available options: WHITE (default) or GRAY
 */
const BonkFieldset: React.FC<{
    title?: string,
    color?: string,

    style?: any,
    className?: string,
    children?: ReactNode,
    [key: string]: any
}> = (props) => {
    const {title, color, className, style, children, ...overflowProps} = props;
    
    let fieldsetColor = "bcl-WHITE"; /* default: bcl-BLACK */
    switch (color) {
        case "WHITE": break;
        case "GRAY": fieldsetColor = "bcl-GRAY"; break;
        
        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkFieldset", `Color "${color}" not available`, "WHITE | BLACK"); break;
    }

    return (
        <div className={`bcl-fieldset ${className || ""}`} style={style}>
            {title !== undefined && <h4 className={`bcl ${fieldsetColor}`}>{title}</h4>}
            <fieldset {...overflowProps}>
                {children}
            </fieldset>
        </div>
    );
};

export default BonkFieldset;
