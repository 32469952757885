import "./components.css";
import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import { BonkInput } from "../BonkInput";

const code =
`<BonkInput
    title="top title text"
    description="Bottom descriptive text"
    color="MAROON"
    errorMessage="Error message"
    fullWidth={true}
/>`;

export function CBonkInput() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Returns an input field for forms or similar purposes"
                updatedOn="08/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" />
                    <p className="bcl parameters">
                        <span>title</span> string<br />
                        <span className="desc">Title text above the input field (not case-sensitive)</span><br /><br />
                        
                        <span>description</span> string<br />
                        <span className="desc">Descriptive text below the input field (case-sensitive)</span><br /><br />

                        <span>color</span> string<br />
                        <span className="desc">Input field color -- Available options: BLACK (default), ORANGE or MAROON</span><br /><br />

                        <span>errorMessage</span> string<br />
                        <span className="desc">Error message on wrong input (case-sensitive) -- If the string is empty (""), the errormessage will be disabled</span><br /><br />
                        
                        <span>fullWidth</span> boolean<br />
                        <span className="desc">Sets input field width to 100% (disabled by default)</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <BonkInput
                            title="Top title text"
                            description="Bottom descriptive text"
                            color="MAROON"
                        />
                        <BonkInput
                            title="Top title text"
                            description="Bottom descriptive text"
                            color="MAROON"
                            errorMessage="Error message"
                        />
                        <div className="preview-background-dark">
                            <div className="flex gap column">  
                                <BonkInput
                                    title="Top title text"
                                    description="Bottom descriptive text"
                                    color="BLACK"
                                />
                                <BonkInput
                                    title="Top title text"
                                    description="Bottom descriptive text"
                                    color="BLACK"
                                    errorMessage="Error message"
                                />
                                <BonkInput
                                    title="Top title text"
                                    description="Bottom descriptive text"
                                    color="ORANGE"
                                />
                                <BonkInput
                                    title="Top title text"
                                    description="Bottom descriptive text"
                                    color="ORANGE"
                                    errorMessage="Error message"
                                />
                            </div>
                            <BonkText description="Dark Background" />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="The best way to implement BonkInput components is within BonkContainer components.<br /><br />When incorporating the BonkInput component, it is essential to adhere to the prescribed color combinations outlined in the BONK Style Guide.<br /><br />Optional parameters do not necessarily have to be defined. If omitted, the parameter will either be completely excluded or the default value will be applied." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
