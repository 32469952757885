import './BonkFooterLink.css';
import { BonkText } from './BonkText';

/**
 * BONKFOOTERLINK COMPONENT
 * Category component for the BonkFooterCategory
 * @param title Category link title text (case-sensitive)
 * @param link Redirection link (internal or external)
 */
export function BonkFooterLink(
    props: Readonly<{
        title: string,
        link?: string,

        style?: any,
        className?: string,
        [key: string]: any
    }>
) {
    const {title, link, className, style, ...overflowProps} = props;

    let footerLinkTitle;
    if (title.replace(/ /g, "").length <= 0) {
        footerLinkTitle = "?????";
    } else {
        footerLinkTitle = title;
    }

    return (
        link ? (
            <a href={link} style={{textDecoration: 'none'}}>
                <BonkText style={style} className={`bcl-footer-link ${className || ""}`} size="SMALL" description={footerLinkTitle} {...overflowProps} />
            </a>
        ) : (
            <BonkText style={style} className={`bcl-footer-link ${className || ""}`} size="SMALL" description={footerLinkTitle} {...overflowProps} />
        )
    );
}
