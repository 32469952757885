import React from "react";
import "./BonkText.css";
import { componentWarnLog } from "../utils/consoleMessage";

/**
 * BONK TEXT COMPONENT
 * Returns a Title and/or a paragraph
 * @param size Text size (adapts for title and paragraph) -- Available options: SMALL, MEDIUM (default), LARGE
 * @param title Title text (not case-sensitive)
 * @param titleColor Title color -- Available options: WHITE (default) or RED
 * @param description Paragraph text (case-sensitive)
 * @param descriptionColor Paragraph color -- Available options: WHITE (default), SILVER or GRAY
 */
export function BonkText(
    props: Readonly<{
        size?: string,
        title?: string,
        titleColor?: string,
        description?: string,
        descriptionColor?: string,
        
        className?: string,
        [key: string]: any
    }>
) {
    const {title, description, size, titleColor, descriptionColor, className, ...overflowProps} = props;

    function addLineBreaks(text: string) {
        return text.split(`<br />`).map((v, i) => (
            <React.Fragment key={i}>
                {v}
                <br />
            </React.Fragment>
        ));
    }
    
    let textSize = "bcl-MEDIUM"; /* default: bcl-MEDIUM */
    switch (size) {
        case "VERYSMALL": textSize = "bcl-VERYSMALL"; break;
        case "SMALL": textSize = "bcl-SMALL"; break;
        case "MEDIUM": break;
        case "LARGE": textSize = "bcl-LARGE"; break;
        
        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkText", `Size "${size}" not available`, "SMALL | MEDIUM | LARGE"); break;
    }

    let titleColorType = "bcl-WHITE"; /* default: bcl-WHITE */
    switch (titleColor) {
        case "WHITE": break;
        case "RED": titleColorType = "bcl-RED"; break;
        case "BLACK": titleColorType = "bcl-BLACK"; break; /* ONLY FOR THE BCL WEBSITE -- SHOULD BE REMOVED IF CREATING NPM */
        
        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkText", `Title color ${titleColor} not available`, "WHITE | RED"); break;
    }

    let descriptionColorType = "bcl-WHITE"; /* default: bcl-WHITE */
    switch (descriptionColor) {
        case "WHITE": break;
        case "SILVER": descriptionColorType = "bcl-SILVER"; break;
        case "GRAY": descriptionColorType = "bcl-GRAY"; break;
        case "LIGHTGRAY": descriptionColorType = "bcl-LIGHTGRAY"; break; /* ONLY FOR THE BCL WEBSITE -- SHOULD BE REMOVED IF CREATING NPM */
        case "MEDIUMGRAY": descriptionColorType = "bcl-MEDIUMGRAY"; break; /* ONLY FOR THE BCL WEBSITE -- SHOULD BE REMOVED IF CREATING NPM */
        case "BLACK": descriptionColorType = "bcl-BLACK"; break; /* ONLY FOR THE BCL WEBSITE -- SHOULD BE REMOVED IF CREATING NPM */
        
        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkText", `Description color ${descriptionColor} not available`, "WHITE | SILVER | GRAY"); break;
    }

    return (
        <div className={`bcl-text ${className || ""}`} {...overflowProps}>
            {title !== undefined && <h1 className={`bcl ${textSize} ${titleColorType}`}>{addLineBreaks(title)}</h1>}
            {description !== undefined && (<p className={`bcl ${textSize} ${descriptionColorType}`}>{addLineBreaks(description)}</p>)}
        </div>
    );
}