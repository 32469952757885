import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import "./components.css";
import BonkFieldset from "../BonkFieldset";
import { BonkBullet } from "../BonkBullet";

const code =
`<BonkFieldset title="Country" color="GRAY" id="set1">
    <BonkBullet color="GRAY" text="Turkey" name="set1"/>
    <BonkBullet color="GRAY" text="Germany" name="set1" defaultChecked={true} />
    <BonkBullet color="GRAY" text="USA" name="set1"/>
</BonkFieldset>`;

export function CBonkBullet() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Returns a fieldset with bullet point selection"
                updatedOn="12/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" /><br />
                    
                    <b><BonkText description="BonkFieldset" descriptionColor="BLACK" /></b>

                    <p className="bcl parameters">
                        <span>title</span> string<br />
                        <span className="desc">Title text (not case-sensitive)</span><br /><br />

                        <span>color</span> string<br />
                        <span className="desc">Fieldset color -- Available options: WHITE (default) or GRAY</span><br /><br />
                    </p>
                    
                    <b><BonkText description="BonkBullet" descriptionColor="BLACK" /></b>

                    <p className="bcl parameters">
                        <span>text</span> string <span className="third-field">required</span><br />
                        <span className="desc">Bullet text (case-sensitive)</span><br /><br />

                        <span>color</span> string<br />
                        <span className="desc">Bullet color -- Available options: WHITE (default), GRAY or SILVER</span><br /><br />

                        <span>defaultChecked</span> boolean<br />
                        <span className="desc">Set bullet enabled by default (disabled by default)</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <BonkFieldset title="Colors" color="GRAY" id="set2">
                            <BonkBullet color="GRAY" text="Blue" name="set2" defaultChecked={true} />
                            <BonkBullet color="GRAY" text="Red" name="set2" />
                            <BonkBullet color="GRAY" text="Green" name="set2"/>
                        </BonkFieldset>
                        <div className="preview-background-dark">
                            <div>
                                <BonkFieldset title="Colors" id="set3">
                                    <BonkBullet text="Blue" name="set3" defaultChecked={true}/>
                                    <BonkBullet text="Red" name="set3" />
                                    <BonkBullet text="Green" name="set3"/>
                                </BonkFieldset>
                            </div>
                            <BonkText description="Dark Background" />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="The best way to implement BonkFieldset components is within BonkContainer components.<br /><br />When incorporating the BonkFieldset/BonkBullet component, it is essential to adhere to the prescribed color combinations outlined in the BONK Style Guide.<br /><br />Optional parameters do not necessarily have to be defined. The specified default value is then applied.<br /><br />Please adhere to the hierarchy of components to create an optimal experience." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
