import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import "./components.css";
import { BonkButton } from "../BonkButton";

const code =
`<BonkButton
    text="button"
    color="WHITE"
    size="SMALL"
    hoverAnimation={true}
    fullWidth={true}
/>`;

export function CBonkButton() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Returns a Button with text"
                updatedOn="08/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" />
                    <p className="bcl parameters">
                        <span>text</span> string <span className="third-field">required</span><br />
                        <span className="desc">Button text (not case-sensitive)</span><br /><br />

                        <span>color</span> string<br />
                        <span className="desc">Button color -- Available options: PEACH (default), RED, MAROON or WHITE</span><br /><br />

                        <span>size</span> string<br />
                        <span className="desc">Button size -- Available options: SMALL, MEDIUM (default), LARGE</span><br /><br />

                        <span>hoverAnimation</span> boolean<br />
                        <span className="desc">Increases button scale on hover (disabled by default)</span><br /><br />

                        <span>fullWidth</span> boolean<br />
                        <span className="desc">Sets button width to 100% (disabled by default)</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <BonkButton
                            text="button"
                            color="PEACH"
                            size="SMALL"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="PEACH"
                            size="MEDIUM"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="PEACH"
                            size="LARGE"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="RED"
                            size="SMALL"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="RED"
                            size="MEDIUM"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="RED"
                            size="LARGE"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="MAROON"
                            size="SMALL"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="MAROON"
                            size="MEDIUM"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                        <BonkButton
                            text="button"
                            color="MAROON"
                            size="LARGE"
                            hoverAnimation={true}
                            fullWidth={false}
                        />
                         <div className="preview-background-dark">
                            <div className="flex gap column">
                                <BonkButton
                                    text="button"
                                    color="WHITE"
                                    size="SMALL"
                                    hoverAnimation={true}
                                    fullWidth={false}
                                />
                                <BonkButton
                                    text="button"
                                    color="WHITE"
                                    size="MEDIUM"
                                    hoverAnimation={true}
                                    fullWidth={false}
                                />
                                <BonkButton
                                    text="button"
                                    color="WHITE"
                                    size="LARGE"
                                    hoverAnimation={true}
                                    fullWidth={false}
                                />
                            </div>
                            <BonkText description="Dark Background" />
                        </div>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />

                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="Optional parameters do not necessarily have to be defined. The specified default value is then applied." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
