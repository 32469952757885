import { BonkText } from "../BonkText";
import { CodeEditor } from "../CodeEditor";
import { ComponentHead } from "../site/ComponentHead";
import "./components.css";
import BonkFooterBody from "../BonkFooterBody";
import BonkFooterCategory from "../BonkFooterCategory";
import { BonkFooterLink } from "../BonkFooterLink";

const code =
`<BonkFooterBody color="RED" socials={true}>
    <BonkFooterCategory title="bonk">
        <BonkFooterLink title="Foundation" link="https://bonkcoin.com/" />
        <BonkFooterLink title="Solana Mobile" link="https://bonkcoin.com/" />
        <BonkFooterLink title="Solana Pay" link="https://bonkcoin.com/" />
        <BonkFooterLink title="Grants" link="https://bonkcoin.com/" />
    </BonkFooterCategory>
    <BonkFooterCategory title="developers">
        <BonkFooterLink title="Documentation" link="https://bonkcoin.com/" />
        <BonkFooterLink title="Mobile SDK" link="https://bonkcoin.com/" />
        <BonkFooterLink title="Pay SDK" link="https://bonkcoin.com/" />
        <BonkFooterLink title="Cookbook" link="https://bonkcoin.com/" />
        <BonkFooterLink title="DAOs" link="https://bonkcoin.com/" />
    </BonkFooterCategory>
</BonkFooterBody>`;

export function CBonkFooterBody() {
    return (
        <div className="component-body">
            <ComponentHead
                description="Returns a footer."
                updatedOn="09/02/2024"
            />
            
            <div className="component-content flex wrap">
                <div className="flex-one">
                    <BonkText title="parameters" titleColor="BLACK" size="SMALL" /><br />

                    <b><BonkText description="BonkFooterBody" descriptionColor="BLACK" /></b>

                    <p className="bcl parameters">
                        <span>color</span> string<br />
                        <span className="desc">Footer color -- Available options: BLACK (default) or RED</span><br /><br />

                        <span>socials</span> boolean<br />
                        <span className="desc">Shows social component (enabled on default)</span><br /><br />
                    </p>

                    <b><BonkText description="BonkFooterCategory" descriptionColor="BLACK" /></b>

                    <p className="bcl parameters">
                        <span>title</span> string <span className="third-field">required</span><br />
                        <span className="desc">Footer category title text (not case-sensitive)</span><br /><br />
                    </p>

                    <b><BonkText description="BonkFooterLink" descriptionColor="BLACK" /></b>

                    <p className="bcl parameters"> 
                        <span>title</span> string <span className="third-field">required</span><br />
                        <span className="desc">Category link title text (case-sensitive)</span><br /><br />

                        <span>link</span> string<br />
                        <span className="desc">Redirection link (internal or external)</span><br /><br />
                    </p>

                    <BonkText className="mt-5 mb-4" title="preview" size="SMALL" titleColor="BLACK" />
                    
                    <div className="flex gap column">
                        <BonkFooterBody color="RED" socials={true} className="preview-footer">
                            <BonkFooterCategory title="bonk">
                                <BonkFooterLink title="Foundation" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Solana Mobile" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Solana Pay" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Grants" link="/components?component=bonkfooterbody" />
                            </BonkFooterCategory>
                            <BonkFooterCategory title="developers">
                                <BonkFooterLink title="Documentation" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Mobile SDK" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Pay SDK" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Cookbook" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="DAOs" link="/components?component=bonkfooterbody" />
                            </BonkFooterCategory>
                        </BonkFooterBody>
                        <BonkFooterBody socials={true} className="preview-footer">
                            <BonkFooterCategory title="bonk">
                                <BonkFooterLink title="Foundation" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Solana Mobile" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Solana Pay" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Grants" link="/components?component=bonkfooterbody" />
                            </BonkFooterCategory>
                            <BonkFooterCategory title="developers">
                                <BonkFooterLink title="Documentation" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Mobile SDK" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Pay SDK" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="Cookbook" link="/components?component=bonkfooterbody" />
                                <BonkFooterLink title="DAOs" link="/components?component=bonkfooterbody" />
                            </BonkFooterCategory>
                        </BonkFooterBody>
                    </div>
                </div>
                <div className="flex-one">
                    <CodeEditor language="html" className="flex-one" code={code} />
                    <div className="component-code-description">
                        <BonkText description="Notice" descriptionColor="BLACK" size="SMALL" />
                        <BonkText description="Optional parameters do not necessarily have to be defined. The specified default value is then applied.<br /><br />Please adhere to the hierarchy of components to create an optimal experience.<br /><br />The preview could be incorrect due to improper implementation." descriptionColor="MEDIUMGRAY" size="SMALL" />
                    </div>
                </div>
            </div>
        </div>
    );
}
