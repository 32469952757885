import './BonkButton.css';
import { componentWarnLog } from '../utils/consoleMessage';

/**
 * BONKBUTTON COMPONENT
 * Returns a Button with text
 * @param text Button text (not case-sensitive)
 * @param color Button color -- Available options: PEACH (default), RED, MAROON or WHITE
 * @param size Button size -- Available options: SMALL, MEDIUM (default), LARGE
 * @param hoverAnimation Increases button scale on hover (disabled by default)
 * @param fullWidth Sets button width to 100% (disabled by default)
 */
export function BonkButton(
    props: Readonly<{
        text: string,
        color?: string,
        size?: string,
        hoverAnimation?: boolean,
        fullWidth?: boolean,

        style?: any,
        className?: string,
        [key: string]: any
    }>
) {
    const {text, color, size, hoverAnimation, fullWidth, style, className, ...overflowProps} = props;

    let buttonColor = "bcl-PEACH"; /* default: bcl-PEACH */
    switch (color) {
        case "PEACH": break;
        case "RED": buttonColor = "bcl-RED"; break;
        case "MAROON": buttonColor = "bcl-MAROON"; break;
        case "WHITE": buttonColor = "bcl-WHITE"; break;

        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkButton", `Color "${color}" not available`, "PEACH | RED | MAROON | WHITE"); break;
    }

    let buttonSize = "bcl-MEDIUM"; /* default: blc-MEDIUM */
    switch (size) {
        case "SMALL": buttonSize = "bcl-SMALL"; break;
        case "MEDIUM": break;
        case "LARGE": buttonSize = "bcl-LARGE"; break;

        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkButton", `Size "${size}" not available`, "SMALL | MEDIUM | LARGE"); break;
    }

    return (
        <div className={`bcl-button ${fullWidth ? "bcl-FULLWIDTH" : ""} ${className || ""}`} style={style}>
            <button className={`${buttonColor} ${hoverAnimation ? "bcl-HOVERANIMATION" : ""} ${buttonSize}`} {...overflowProps}>
                {text}
            </button>
        </div>
    );
}
