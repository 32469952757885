import BonkFooterBody from '../BonkFooterBody';
import BonkFooterCategory from '../BonkFooterCategory';
import { BonkFooterLink } from '../BonkFooterLink';
import './Footer.css';

export function Footer() {
    return (
        <BonkFooterBody socials={true}>
            <BonkFooterCategory title="bonk">
                <BonkFooterLink title="Bonk Site" link="https://bonkcoin.com/" />
            </ BonkFooterCategory>
            <BonkFooterCategory title="developers">
                <BonkFooterLink title="Documentation" link="https://www.google.de/" />
                <BonkFooterLink title="Style Guide" link="https://www.google.de/" />
                <BonkFooterLink title="GitHub" link="https://www.google.de/" />
            </ BonkFooterCategory>
        </BonkFooterBody>
    );
}
