import { Link, useLocation } from 'react-router-dom';
import { BonkText } from '../BonkText';
import './ComponentsNav.css';

export function ComponentsNav() {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const component = params.get("component");
    
    const componentsArray: string[] = ["BonkBadge", "BonkButton", "BonkContainer", "BonkFooterBody", "BonkInput", "BonkPattern", "BonkText", "BonkBullet", "BonkDate"];
    const sortedComponentsArray = [...componentsArray].sort();

    return (
        <div className="componentsnav">
            {sortedComponentsArray.map((v: string, i: number) => (
                <Link to={`/components?component=${v.toLowerCase()}`} key={i}>
                    <div className={`tab-button ${(component !== null && component === v.toLowerCase()) && "selected"}`}>
                        <BonkText size="SMALL" descriptionColor="MEDIUMGRAY" description={v} />
                    </div>
                </Link>
            ))}
        </div>
    );
}
