import './BonkPattern.css';
import { componentWarnLog } from '../utils/consoleMessage';

/**
 * BONKPATTERN COMPONENT
 * Returns a background logo pattern
 * @param width Pattern width (can be in "px", "%" or similar)
 * @param height Pattern height (can be in "px", "%" or similar)
 * @param position Pattern position -- Available options: RELATIVE (default) or ABSOLUTE
 * @param size Pattern logo size -- Range: 150 - 500 (default: 200)
 * @param opacity Pattern logo opacity -- Range: 5 - 20 (default: 10)
 * @param mobileTrigger When mobile view will be enabled (default: 768)
 * @param mobileMultiplicator Mobile view pattern size multiplicator (default: 2 (recommended))
 */
export function BonkPattern(
    props: Readonly<{
        width?: string,
        height?: string,
        position?: string,
        size?: number,
        opacity?: number,
        mobileTrigger?: number,
        mobileMultiplicator?: number,
        
        className?: string,
        [key: string]: any
    }>
) {
    const {width, height, position, size, opacity, mobileTrigger, className, mobileMultiplicator, style, ...overflowProps} = props;

    let isMobile;
    if (mobileTrigger !== undefined) {
        isMobile = window.innerWidth <= mobileTrigger;
    } else {
        isMobile = window.innerWidth <= 768; /* default */
    }

    let patternPosition = "bcl-RELATIVE";
    switch (position) { /* default "bcl-RELATIVE" */
        case "REALTIVE": break;
        case "ABSOLUTE": patternPosition = "bcl-ABSOLUTE"; break;

        case undefined: break;
        case "": break;
        default: componentWarnLog("BonkContainer", `Position "${position}" not available`, "RELATIVE | ABSOLUTE"); break;
    }

    let patternSize = 200; /* default */
    if (size !== undefined) {
        if (size >= 150 && size <= 500) {
            patternSize = size;
        } else {
            componentWarnLog("BonkContainer", `Size "${size}" out of range`, "150 - 500");
        }
    }

    if (isMobile !== false) {
        if (mobileMultiplicator !== undefined) {
            patternSize = patternSize * mobileMultiplicator;
        } else {
            patternSize = patternSize * 2; /* default */
        }
    }

    let patternOpacity = 10; /* default */
    if (opacity !== undefined) {
        if (opacity >= 5 && opacity <= 20) {
            patternOpacity = opacity;
        } else {
            componentWarnLog("BonkContainer", `Opacity "${opacity}" out of range`, "5 - 20");
        }
    }

    return (
        <div
            className={`bcl-pattern ${patternPosition} ${className || ""}`}
            style={{
                width: `${width}`,
                height: `${height}`,
                backgroundSize: `${patternSize}%`,
                opacity: `${patternOpacity}%`
            }}
            {...overflowProps}
        />
    );
}
