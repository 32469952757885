/* DO NOT CHANGE THE ORDER OF THE INPORTS */
import "./CodeEditor.css";
import AceEditor from "react-ace";
import Copy from "../static/assets/copy.png"
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-kuroir";
import 'ace-builds/src-noconflict/ace';

/**
 * BONK CODE EDITOR COMPONENT
 * @param width Width
 * @param code Code content
 * @param language html, typescript...
 */
export function CodeEditor(
    props: Readonly<{
        code?: string,
        language: string,
        width?: string,
        className?: string
    }>
) {
    const {code, language, width, className} = props;

    async function handleCopyCode() {
        try {
            await navigator.clipboard.writeText(`${code !== undefined && code}`);
        } catch (err) {
            console.error(err);
        }
    };
    
    return (
        <div className={`code-editor ${className || ""}`} style={{width: width}}>
            <img alt="copy logo" src={Copy} width="25px" title="Copy code" onClick={handleCopyCode} />
            <AceEditor
                mode={language}
                theme="kuroir"
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                setOptions={{ useWorker: false, showPrintMargin: false, highlightActiveLine: false, showFoldWidgets: false }}
                value={code}
                readOnly={true}
                fontSize="1.1em"
                width={`${width}`}
                maxLines={100}
                cursorStart={0}
                focus={false}
            />
        </div>
    );
}
